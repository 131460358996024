import "src/style/components/UtilsComponent.scss";
import {
    Button,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Switch,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useAppSelector } from "src/hooks/useAppSelector";
import { fetchWithAuth, getTarget } from "src/utils/api";
import {
    AppRoles,
    EmployeeType,
    ExportWorkStatementRequest,
    GenericMessageResponse,
    GetEurRateDto,
    OfferType,
    Timesheet,
} from "src/types";
import LoadingText from "src/components/LoadingText";
import dayjs, { Dayjs } from "dayjs";
import { getRoles } from "src/utils/auth";
import { useMsal } from "@azure/msal-react";

export default function UtilsComponent(): ReactElement {
    const theme = useTheme();
    const { instance } = useMsal();

    const [deleteTlDate, setDeleteTlDate] = useState<Dayjs | null>(dayjs());
    const [deleteTlUser, setDeleteTlUser] = useState<string>("");
    const [deleteTlAll, setDeleteTlAll] = useState(false);
    const [deleteTlLoading, setDeleteTlLoading] = useState(false);
    const [deleteTlMessage, setDeleteTlMessage] = useState<JSX.Element>();

    const [timesheets, setTimesheets] = useState<Timesheet[]>([]);
    const [exportWSSelectedTimesheet, setExportWSSelectedTimesheet] =
        useState<Timesheet>();
    const [exportWSLoading, setExportWSLoading] = useState(false);
    const [exportWSMessage, setExportWSMessage] = useState<JSX.Element>();
    const [exportWSRph, setExportWSRph] = useState<number>(0);
    const [exportWSE2RConv, setExportWSE2RConv] = useState<number>(5);

    const [roles] = useState(getRoles(instance));

    const errors = useAppSelector((state) => state.app.errors);
    const employee = useAppSelector((state) => state.user.employee);

    const getEurRate = async () => {
        await fetchWithAuth<GetEurRateDto>(
            getTarget() + "/api/ExportTimesheets/eur-rate",
        ).then(async (_result) => {
            setExportWSE2RConv(_result.rate);
        });
    };

    const getTimesheets = async () => {
        await fetchWithAuth<Timesheet[]>(
            getTarget() + "/api/ZohoTimesheet",
        ).then(async (_timesheets) => {
            setTimesheets(_timesheets);
            if (_timesheets.length > 0) {
                setExportWSSelectedTimesheet(_timesheets[0]);
            }
        });
    };

    const handleExportWSSelectedTimesheetChange = (
        event: SelectChangeEvent,
    ): void => {
        const _timesheetId = event.target.value as string;
        const _timesheet = timesheets.find(
            (_t) => _t.recordId === _timesheetId,
        ) as Timesheet;

        if (_timesheet) {
            setExportWSSelectedTimesheet(_timesheet);
        }
    };

    const handleExportWSRphChange = (event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        const regex = /^\d*\.?\d{0,4}$/;
        if (regex.test(inputValue)) {
            setExportWSRph(parseFloat(inputValue));
        }
    };

    const handleExportWSE2RConvChange = (
        event: ChangeEvent<HTMLInputElement>,
    ) => {
        const inputValue = event.target.value;

        const regex = /^\d*\.?\d{0,4}$/;
        if (regex.test(inputValue)) {
            setExportWSE2RConv(parseFloat(inputValue));
        }
    };

    const handleExportWS = async (): Promise<void> => {
        setExportWSLoading(true);

        const requestBody: ExportWorkStatementRequest = {
            timesheet: exportWSSelectedTimesheet!,
            employee: employee!,
            ratePerHour: exportWSRph!,
            eurToRonConversionRate: exportWSE2RConv!,
        };

        const request = new Request(
            getTarget() + "/api/exportTimesheets/work-statement",
            {
                method: "post",
                body: JSON.stringify(requestBody),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            },
        );

        await fetchWithAuth<Blob>(request)
            .catch((e: Error) => {
                setExportWSMessage(
                    <Typography style={{ color: theme.palette.error.main }}>
                        {errors[0]?.message}
                    </Typography>,
                );

                // if (e.message.indexOf("403") > -1) {
                //     setDeleteTlMessage(
                //         <Typography>
                //             <span style={{ color: theme.palette.error.main }}>
                //                 Insufficient permissions!
                //             </span>{" "}
                //         </Typography>,
                //     );
                // }

                // if (e.message.indexOf("404") > -1) {
                //     setDeleteTlMessage(
                //         <Typography>
                //             <span style={{ color: theme.palette.error.main }}>
                //                 No deletable time logs found for specified
                //                 month.
                //             </span>{" "}
                //         </Typography>,
                //     );
                // }
            })
            .finally(() => {
                setExportWSLoading(false);
            });
    };

    const handleDeleteTl = async (): Promise<void> => {
        setDeleteTlLoading(true);

        const request = new Request(
            getTarget() +
                `/api/zohotimelog?month=${deleteTlDate?.format(
                    "DD-MMM-YYYY",
                )}&user=${deleteTlUser}&deleteAll=${deleteTlLoading}`,
            {
                method: "delete",
            },
        );

        await fetchWithAuth<GenericMessageResponse>(request)
            .then((_response) => {
                setDeleteTlMessage(
                    <Typography style={{ color: theme.palette.success.main }}>
                        {_response.message}
                    </Typography>,
                );
            })
            .catch((e: Error) => {
                setDeleteTlMessage(
                    <Typography style={{ color: theme.palette.error.main }}>
                        {errors[0]?.message}
                    </Typography>,
                );

                if (e.message.indexOf("403") > -1) {
                    setDeleteTlMessage(
                        <Typography>
                            <span style={{ color: theme.palette.error.main }}>
                                Insufficient permissions!
                            </span>{" "}
                        </Typography>,
                    );
                }

                if (e.message.indexOf("404") > -1) {
                    setDeleteTlMessage(
                        <Typography>
                            <span style={{ color: theme.palette.error.main }}>
                                No deletable time logs found for specified
                                month.
                            </span>{" "}
                        </Typography>,
                    );
                }
            })
            .finally(() => setDeleteTlLoading(false));
    };

    useEffect(() => {
        setExportWSLoading(true);
        Promise.all([getEurRate(), getTimesheets()]).finally(() =>
            setExportWSLoading(false),
        );
    }, []);

    return (
        <div className="utils-component">
            <div className="utils-component__section">
                <Typography
                    fontSize={22}
                    display="block"
                    fontFamily="Syne"
                    fontWeight={600}
                    gutterBottom
                    marginTop={2}
                >
                    <span style={{ fontWeight: 800 }}>Delete </span> time logs
                    for the{" "}
                    <span style={{ fontWeight: 800 }}>entire month</span>
                </Typography>
                <Typography>
                    Sometimes, the upload process can produce errors, human or
                    software related, that can result in the wrong number of
                    hours being uploaded into Zoho People, or things like
                    duplicate entries. <br />
                    With a click of a button, you can remove all the entries in
                    a month (granted they were not submitted for approval) and
                    start over!
                </Typography>
                <div className="utils-component__section__form">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label={"Month to delete"}
                            views={["year", "month"]}
                            value={deleteTlDate}
                            onChange={(value) =>
                                value && setDeleteTlDate(value)
                            }
                            minDate={dayjs("2021-01-01")}
                            disabled={deleteTlLoading}
                        />
                    </LocalizationProvider>
                    {roles?.includes(AppRoles.BACKOFFICE) && (
                        <TextField
                            label="User"
                            variant="outlined"
                            value={deleteTlUser}
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                                setDeleteTlUser(event.target.value);
                            }}
                            placeholder="name@principal33.com"
                            type="email"
                            error={
                                deleteTlUser !== "" &&
                                !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(
                                    deleteTlUser,
                                )
                            }
                            disabled={deleteTlLoading}
                        />
                    )}

                    <Stack
                        direction="row"
                        spacing={1}
                        sx={{ alignItems: "center" }}
                    >
                        <Typography>
                            Delete only time logs generated by this tool
                        </Typography>
                        <Switch
                            aria-label="Delete"
                            checked={deleteTlAll}
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                            ) => setDeleteTlAll(event.target.checked)}
                            disabled={deleteTlLoading}
                            color="secondary"
                        />
                        <Typography>Delete all time logs</Typography>
                    </Stack>
                </div>
                <Stack
                    direction="row"
                    spacing={3}
                    sx={{ alignItems: "center", height: "48px" }}
                >
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={handleDeleteTl}
                        disabled={deleteTlLoading}
                    >
                        Delete time logs
                    </Button>
                    {deleteTlLoading && <LoadingText />}
                </Stack>
                <div className="utils-component__section__message">
                    {deleteTlMessage}
                </div>
                <Typography variant="caption">
                    <em>
                        <strong>Important!</strong> Time logs cannot be deleted
                        if they are included in an already-submitted timesheet.
                        To delete the timesheet first, it's essential you
                        contact the Finance Team.
                    </em>
                </Typography>
            </div>
            {employee?.type !== EmployeeType.CIM && (
                <div className="utils-component__section">
                    <Typography
                        fontSize={22}
                        display="block"
                        fontFamily="Syne"
                        fontWeight={600}
                        gutterBottom
                        marginTop={2}
                    >
                        Generate{" "}
                        <span style={{ fontWeight: 800 }}>Work Statement</span>
                    </Typography>
                    <Typography>
                        Easily generate your Work Statement document. A PDF file
                        will be exported, containing all the work items from the
                        selected timesheet. <br />
                        The total ammount is calculated based on the{" "}
                        <strong>hours in the timesheet</strong> and the
                        information you manually input (
                        <strong>rate per hour</strong> and{" "}
                        <strong>EUR to RON conversion rate</strong>).
                    </Typography>
                    <div className="utils-component__section__form">
                        <Select
                            value={
                                exportWSSelectedTimesheet
                                    ? exportWSSelectedTimesheet.recordId
                                    : ""
                            }
                            onChange={handleExportWSSelectedTimesheetChange}
                            displayEmpty
                            inputProps={{
                                "aria-label": "Without label",
                                MenuProps: { disableScrollLock: true },
                            }}
                            sx={{
                                width: "100%",
                                maxWidth: 255,
                            }}
                        >
                            {timesheets.map((timesheet) => (
                                <MenuItem
                                    value={timesheet.recordId}
                                    key={timesheet.recordId}
                                >
                                    {timesheet.timesheetName}
                                </MenuItem>
                            ))}
                        </Select>
                        <TextField
                            type="number"
                            onChange={handleExportWSRphChange}
                            value={exportWSRph}
                            slotProps={{ htmlInput: { step: "1", min: "0" } }}
                            label={
                                employee?.offerType === OfferType.Fixed
                                    ? "Fixed rate"
                                    : "Rate per hour"
                            }
                        />
                        <TextField
                            type="number"
                            onChange={handleExportWSE2RConvChange}
                            value={exportWSE2RConv}
                            slotProps={{
                                htmlInput: { step: "0.0001", min: "0" },
                            }}
                            label="EUR to RON conversion rate"
                        />
                    </div>
                    <Stack
                        direction="row"
                        spacing={3}
                        sx={{ alignItems: "center", height: "48px" }}
                    >
                        <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            onClick={handleExportWS}
                            disabled={
                                exportWSLoading ||
                                !exportWSE2RConv ||
                                !exportWSRph
                            }
                        >
                            generate Work Statement
                        </Button>
                        {exportWSLoading && <LoadingText />}
                    </Stack>
                    <div className="utils-component__section__message">
                        {exportWSMessage}
                    </div>
                    <Typography variant="caption">
                        <em>
                            <strong>Important!</strong> Please make sure the sum
                            on the Work Statement document{" "}
                            <strong>exactly matches</strong> with your Invoice.
                        </em>
                    </Typography>
                </div>
            )}
        </div>
    );
}
